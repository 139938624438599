import {
    BrowserRouter,
    Routes,
    Route
} from "react-router-dom";
import App from "../pages/App";
import CandyMachineState from "../pages/CandyMachineState";
import Wallet from "../providers/Wallet";
import CandyMachineProvider from "../providers/CandyMachine";
import AppContext from "../providers/AppContext";
import React from "react";


function AppPage() {
    return (
        <AppContext>
            <Wallet>
                <CandyMachineProvider>
                    <App />
                </CandyMachineProvider>
            </Wallet>
        </AppContext>
    )
}

function CandyPage() {
    return (
        <Wallet>
            <CandyMachineProvider>
                <CandyMachineState />
            </CandyMachineProvider>
        </Wallet>
    )
}


function AppRouter() {
    return (
        <BrowserRouter>
            <Routes>
                <Route path="/f9Gjff53454534sdFJSfwef8fwhf9fw4" element={<CandyPage />} />
                <Route path="/" element={<AppPage />} />
            </Routes>
        </BrowserRouter>
    )
}


export default AppRouter