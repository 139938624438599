import { useWallet } from "@solana/wallet-adapter-react";
import { WalletModalButton } from "@solana/wallet-adapter-react-ui";

import styles from "./../styles/MintButton.module.css";
import {memo} from "react";

function MintButton({ onClick, unmintedDucks }: { onClick?: () => any, unmintedDucks: number }) {
  const { connected } = useWallet();

  if(!connected){
    return <WalletModalButton className={styles.mintBtn} disabled={unmintedDucks <= 0}>{unmintedDucks === 0 ? "SOLD OUT" : "Connect to Wallet"}</WalletModalButton>
  }else{
    return (
        <button className={styles.mintBtn}  disabled={unmintedDucks <= 0}>
            {unmintedDucks === 0 ? "SOLD OUT" : "Mint A Duck"}
        </button>
    )
  }
}

export default memo(MintButton);
