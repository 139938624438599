import { useContext } from "react";
import {
  CandyMachineContext,
  CandyMachineDispatch,
} from "../providers/CandyMachine";

function useCandyMachine() {
  const state = useContext(CandyMachineContext);
  const dispatch = useContext(CandyMachineDispatch);

  return { dispatch, ...state };
}

export default useCandyMachine;
