import React, { useEffect, useCallback} from "react";
import styles from "./../styles/Main.module.css";

import bgImage from "./../assets/images/home-bg-3.jpg";
import logo from "./../assets/images/logo-2.png";
import powered from "./../assets/images/powered.webp";
import Particles from "../components/Particles";
import Duck from "../components/Dock";
import Clouds from "../components/Clouds";
import Container from "@mui/material/Container";
import { useTransition, animated } from "react-spring";

import styled from "@mui/system/styled";
import { useWallet, useConnection } from "@solana/wallet-adapter-react";

import MintButton from "../components/MintButton";


import * as anchor from "@project-serum/anchor";

import {
  awaitTransactionSignatureConfirmation,
  mintOneToken,
} from "../utils/candyMachine";
import useCandyMachine from "../hooks/useCandyMachine";
import {WalletMultiButton} from "@solana/wallet-adapter-react-ui";
import StatusModal, {StatusType} from "../components/StatusModal";
import {useAppDispatch, useAppStore} from "../hooks/useAppStore";
import {fetchUnmintedDucks, getImage, validateAddress} from "../api";


const MainContainer = styled("div")`
  background-image: url(${bgImage});
`;

const CONFIG_PUBKEY = new anchor.web3.PublicKey(
  process.env.REACT_APP_CANDY_MACHINE_CONFIG!
);



function App() {
  const { connection } = useConnection();
  const { publicKey, wallet, connected } = useWallet();
  const { candyMachine } = useCandyMachine();

  const { config, status, isValid, image, unmintedDucks } = useAppStore()
  const dispatch = useAppDispatch()




  // validate
  useEffect(() => {
    if(connected && publicKey)
      validateAddress(dispatch, publicKey)
  },[connected, publicKey, dispatch])


  const handleCloseStatusModal = useCallback(() => {
    dispatch({
      type: 'clearStatus'
    })
  }, [dispatch])


  const handleMintNFT = useCallback(async () => {
    if(!publicKey || !wallet || !candyMachine?.program || !config?.walletAddress || !isValid) return;
    validateAddress(dispatch, publicKey).then(() => {
      fetchUnmintedDucks(dispatch).then(() => {
        dispatch({
          type: "setStatus",
          payload: {
            value: 'InProgress',
            message: "Pending transaction ..."
          }
        })

        mintOneToken(
            candyMachine,
            CONFIG_PUBKEY,
            publicKey,
            config?.walletAddress
        ).then(({mintTrxId, mintPubKey}) => {

          dispatch({
            type: "setStatus",
            payload: {
              value: 'InProgress',
              message: "Minting your NFT ..."
            }
          })

          awaitTransactionSignatureConfirmation(
              mintTrxId,
              30000,
              connection,
              "singleGossip",
              false
          ).then(_status => {
            getImage(dispatch, mintPubKey.toString(), publicKey.toString())
          }).catch(e => {
            dispatch({
              type: "setStatus",
              payload: {
                value: 'Error',
                message: "Transaction is not valid!"
              }
            })
          })

        }).catch(e => {
          if(e?.code === 309){
            dispatch({
              type: "setStatus",
              payload: {
                value: 'Error',
                message: "Uh-oh. It looks like there are insufficient funds to complete this transaction. Please try again."
              }
            })
          }else if(e?.error?.code === -32603){
            dispatch({
              type: "clearStatus"
            })
          }else{
            dispatch({
              type: "setStatus",
              payload: {
                value: 'Error',
                message: e?.msg ? "Uh-oh. It looks like we were unable to complete the transaction. Please try again.":
                    "Uh-oh. It looks like the Solana network is congested, please try again shortly."
              }
            })
          }

        });

      }).catch(() => {})
    }).catch(() => {})

    },[publicKey, candyMachine, wallet])


  const contentTransition = useTransition(true, {
    from: { y: 150, opacity: 0, blur: 10 },
    enter: { y: 0, opacity: 1, blur: 0 },
    leave: { y: 150, opacity: 0, blur: 10 },
    config: {
      mass: 2.3,
      tension: 99,
    },
  });

  return (
      // <Suspense fallback={<Loading />}>
        <MainContainer className={styles.container}>
          <Particles />
          <Clouds />
          <Container className={styles.content} maxWidth={false}>
            <img src={logo} alt="dazedducks" />
            {contentTransition(
                ({ blur, ..._styles }, content) =>
                    content && (
                        <animated.div
                            style={{
                              ..._styles,
                              filter: blur.to((val) => `blur(${val}px)`),
                            }}
                        >
                          <React.Fragment>
                            <p>
                              10,000 Dazed Ducks On A Metagalactic Mission.
                            </p>
                            <div className={styles.count}>0 Dazed Ducks Available to Mint</div>
                            <MintButton onClick={handleMintNFT} unmintedDucks={0} />
                          </React.Fragment>
                        </animated.div>
                    )
            )}
          </Container>
          <Duck />
          <img className={styles.powered} src={powered} alt="duck" />
          {
              connected && (<div className={styles.disconnectBtn}>
                <WalletMultiButton />
              </div>)
          }

          <StatusModal
              status={status.value}
              show={!!status.value}
              message={status.message}
              image={image?.uri}
              explorerUrl={image?.explorer}
              onClose={handleCloseStatusModal}
              onRemint={handleMintNFT} />



        </MainContainer>
      // </Suspense>
  );
}

export default App;
