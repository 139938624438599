import React, {useEffect, useMemo, useState} from "react";
import {
  ConnectionProvider,
  WalletProvider,
} from "@solana/wallet-adapter-react";
import { WalletAdapterNetwork } from "@solana/wallet-adapter-base";
import {
  getPhantomWallet,
  getSolflareWallet,
  getSolletExtensionWallet,
} from "@solana/wallet-adapter-wallets";
import { WalletModalProvider } from "@solana/wallet-adapter-react-ui";
import { clusterApiUrl } from "@solana/web3.js";
import {useAppStore} from "../hooks/useAppStore";
require("@solana/wallet-adapter-react-ui/styles.css");

function Wallet({ children }: { children: React.ReactNode }) {
    const [network, setNetwork] = useState(WalletAdapterNetwork.Mainnet)
    const { config } = useAppStore()

    useEffect(() => {
        if(config) setNetwork(config.network as WalletAdapterNetwork)
    }, [config])


  const endpoint = useMemo(() => clusterApiUrl(network), [network]);
  const wallets = useMemo(
    () => [
      getPhantomWallet(),
      getSolflareWallet(),
      getSolletExtensionWallet({ network }),
    ],
    [network]
  );

  return (
    <ConnectionProvider endpoint={endpoint}>
      <WalletProvider wallets={wallets} autoConnect>
        <WalletModalProvider>{children}</WalletModalProvider>
      </WalletProvider>
    </ConnectionProvider>
  );
}

export default Wallet;
