import React, {useEffect} from 'react'
import styles from './../styles/StatusModal.module.css'
import {CircularProgress} from "@mui/material";
import {ReactComponent as ErrorIcon} from "./../assets/icons/error.svg";
import {ReactComponent as ArrowIcon} from "./../assets/icons/arrow-right.svg";


export type StatusType = 'InProgress' | 'Error' | 'Successful'

export type StatusModalProps = {
    show: boolean,
    status?: StatusType,
    message?: string,
    image?: string,
    explorerUrl?: string,
    onClose?: () => any,
    onRemint: () => void
}

function StatusModal({ show, status, message, image, onClose, explorerUrl, onRemint }:StatusModalProps) {

    useEffect(() => {
        const windowClickHandler = () => {
            if(onClose && (status === 'Error' || status === 'Successful')) onClose()
        }
        window.addEventListener('click', windowClickHandler)
        return () => {
            window.removeEventListener('click', windowClickHandler)
        }
    },[onClose, status])

    return show ? (
        <div className={styles.container}>
                <div className={styles.modal} onClick={(e) => e.stopPropagation()}>
                    {
                        (status === 'Successful' || status === 'Error') && (
                            <div className={styles.closeBtn} onClick={() => onClose && onClose()}>
                                <ErrorIcon />
                            </div>
                        )
                    }
                    {status === 'InProgress' && (
                            <div className={styles.loadingIcon}>
                                <CircularProgress color="inherit" />
                            </div>
                    )}
                    {/*{status === 'Error' && (*/}
                    {/*        <div className={styles.errorIcon}>*/}
                    {/*            <ErrorIcon />*/}
                    {/*        </div>*/}
                    {/*)}*/}
                    {(image && status === 'Successful') && (
                        <div className={styles.image}>
                            <img src={image} alt={"dazed ducks"} />
                        </div>
                    )}
                    {message && (
                        <div className={`${styles.content}${status === 'Error' ? ` ${styles.error}` : ''}`}>
                            {message}
                        </div>
                    )}
                    {(status === 'Successful' && explorerUrl) && (
                            <div className={styles.btns}>
                                <a href={explorerUrl} target={"_blank"}>
                                    <p>check in solana explorer</p>
                                    <ArrowIcon />
                                </a>
                                <button onClick={onRemint}>Mint again</button>
                            </div>
                    )}

                </div>
        </div>
    ) : null
}

export default StatusModal