import React, {createContext, useEffect, useReducer} from 'react'
import {Action, AppStateType} from "./types";
import {fetchConfig, fetchUnmintedDucks} from "../api";
import Loading from "../components/Loading";



function reducer(state: AppStateType, action: Action): AppStateType {
    switch (action.type) {
        case 'setConfig':
            return {
                ...state,
                config: action.payload,
            };
        case 'setImage':
            return {
                ...state,
                image: action.payload
            }
        case 'setStatus':
            return {
                ...state,
                status: action.payload
            }
        case 'clearStatus':
            return {
                ...state,
                status: {}
            }
        case 'setValid':
            return {
                ...state,
                isValid: action.payload
            }
        case 'setUnmintedDucks':
            return {
                ...state,
                unmintedDucks: action.payload
            }
        default:
            throw new Error();
    }
}

export const appStateDefaultValue: AppStateType = {
    status: {},
    unmintedDucks: 0
}

export const AppStateContext = createContext<AppStateType>(appStateDefaultValue)
export const AppDispatchContext = createContext<React.Dispatch<Action>>(() => {})

function AppProvider({children}: { children: React.ReactElement}) {
    const [state, dispatch] = useReducer(reducer, appStateDefaultValue)

    useEffect(() => {
        fetchConfig(dispatch)
        fetchUnmintedDucks(dispatch)
        const interval = setInterval(() => {
            fetchUnmintedDucks(dispatch)
        }, 30000)
        return () => {
            clearInterval(interval)
        }
    },[])


    return (
        <AppDispatchContext.Provider value={dispatch}>
            <AppStateContext.Provider value={state}>
                {state.config ? children : <Loading />}
            </AppStateContext.Provider>
        </AppDispatchContext.Provider>
    );
}

export default AppProvider