import React, { useState, createContext, useEffect, useContext } from "react";
import { useAnchorWallet, useConnection } from "@solana/wallet-adapter-react";
import { CandyMachine, getCandyMachineState } from "../utils/candyMachine";
import * as anchor from "@project-serum/anchor";

const candyMachineId = new anchor.web3.PublicKey(
  process.env.REACT_APP_CANDY_MACHINE_ID!
);

const startDateSeed = parseInt(process.env.REACT_APP_CANDY_START_DATE!, 10);

export type CandyMachineState = {
  itemsAvailable: number;
  itemsRedeemed: number;
  itemsRemaining: number;
  isSoldOut: boolean;
  startDate: Date;
  candyMachine: CandyMachine | undefined;
};

export const initialState: CandyMachineState = {
  itemsAvailable: 0,
  itemsRedeemed: 0,
  itemsRemaining: 0,
  isSoldOut: false,
  startDate: new Date(startDateSeed),
  candyMachine: undefined,
};

export const CandyMachineContext = createContext(initialState);
export const CandyMachineDispatch = createContext<
  React.Dispatch<React.SetStateAction<CandyMachineState>>
>(() => {});

function CandyMachineProvider({ children }: { children: React.ReactNode }) {
  const [state, dispatch] = useState(initialState);
  const wallet = useAnchorWallet();
  const { connection } = useConnection();

  useEffect(() => {
    (async () => {
      if (!wallet) return;
      const {
        candyMachine,
        goLiveDate,
        itemsAvailable,
        itemsRemaining,
        itemsRedeemed,
      } = await getCandyMachineState(
        wallet as anchor.Wallet,
        candyMachineId,
        connection
      );

      dispatch({
        itemsAvailable,
        itemsRemaining,
        itemsRedeemed,
        isSoldOut: itemsRemaining === 0,
        startDate: goLiveDate,
        candyMachine,
      });
    })();
  }, [wallet, connection]);

  return (
    <CandyMachineDispatch.Provider value={dispatch}>
      <CandyMachineContext.Provider value={state}>
        {children}
      </CandyMachineContext.Provider>
    </CandyMachineDispatch.Provider>
  );
}

export default CandyMachineProvider;
