import { memo } from "react";
import { useTransition, animated } from "react-spring";
import styles from "./../styles/Dock.module.css";
import duck from "./../assets/images/duck-1.png";

function Dock() {
  const imgDock = <img src={duck} alt="duck" />;

  const duckTransition = useTransition(imgDock, {
    from: { y: 150, x: -150, opacity: 0, blur: 10 },
    enter: { y: 0, x: 0, opacity: 1, blur: 0 },
    leave: { y: 150, x: -150, opacity: 0, blur: 10 },
    config: {
      mass: 2.3,
      tension: 99,
    },
  });

  return duckTransition(
    ({ blur, ..._styles }, content) =>
      content && (
        <animated.div
          style={{ ..._styles, filter: blur.to((val) => `blur(${val}px)`) }}
          className={styles.duckImage}
        >
          {content}
        </animated.div>
      )
  );
}

export default memo(Dock);
