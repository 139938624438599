import React, {memo} from "react";
import styles from "./../styles/Loading.module.css";
import CircularProgress from "@mui/material/CircularProgress";

function Loading() {
  return (
    <div className={styles.loading}>
      <CircularProgress sx={{ color: "#fff" }} />
    </div>
  );
}

export default memo(Loading);
