import React, {useEffect, useState} from 'react'
import {getCandyMachineState} from "../utils/candyMachine";
import * as anchor from "@project-serum/anchor";
import {PublicKey} from "@solana/web3.js";
import {useAnchorWallet, useConnection} from "@solana/wallet-adapter-react";


function CandyMachineState() {

    const [state, setState] = useState<any>({})
    const [loading, setLoading] = useState(true)

    const wallet = useAnchorWallet();
    const { connection } = useConnection();

    async function getState() {
        return await getCandyMachineState(wallet as anchor.Wallet,new PublicKey(`${process.env.REACT_APP_CANDY_MACHINE_ID}`), connection)
    }

    const fetchData = () => {
        setLoading(true)
        getState().then(res => {
            setState(res)
            setLoading(false)
        }).catch(e => {
            console.log(e)
            setLoading(false)
        })
    }

    useEffect(() => {
        fetchData()
    },[])

    return (
        <div style={{
            padding: '20px'
        }}>
            {loading ? 'loading ...' : <pre>{JSON.stringify({
                items_available: state.itemsAvailable,
                items_redeemed: state.itemsRedeemed,
                items_remaining: state.itemsRemaining,
                go_live_date: state.goLiveDate,
            }, null, 1)}</pre>}
            <br />
            <br />
            <button onClick={fetchData}>Refetch</button>
        </div>
    )
}



export default CandyMachineState