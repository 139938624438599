// import axios from "axios";
// import React from "react";
// import {Action} from "../providers/types";
// import {clusterApiUrl, Connection, PublicKey} from "@solana/web3.js";
// import {getCandyMachineState} from "../utils/candyMachine";
// import * as anchor from "@project-serum/anchor";
// import {WalletAdapterNetwork} from "@solana/wallet-adapter-base";
//
//
// export function fetchUnmintedDucks(dispatch: React.Dispatch<Action>) {
//     return new Promise<void>((resolve, reject) => {
//         const wallet: any = undefined;
//         getCandyMachineState(wallet as anchor.Wallet, new PublicKey(`${process.env.REACT_APP_CANDY_MACHINE_ID}`),
//         new Connection(clusterApiUrl(WalletAdapterNetwork.Mainnet))).then(res => {
//             dispatch({
//                 type: 'setUnmintedDucks',
//                 payload: Number(res.itemsRemaining)
//             })
//             if (Number(res.itemsRemaining) > 0) {
//                 resolve()
//             } else {
//                 reject()
//             }
//         }).catch(e => {
//             reject(e)
//         })
//     });
// }

import axios from "axios";
import React from "react";
import {Action} from "../providers/types";
import {PublicKey} from "@solana/web3.js";


export function fetchUnmintedDucks(dispatch: React.Dispatch<Action>){
    return new Promise<void>((resolve, reject) => {
        axios.get(`${process.env.REACT_APP_BASE_URL}/un-minted-ducks`)
            .then((res) => {
                if(res.status === 200){
                    dispatch({
                        type: 'setUnmintedDucks',
                        payload: res.data.data
                    })
                    if(Number(res.data.data) > 0){
                        resolve()
                    }else{
                        reject()
                    }
                }else{
                    reject()
                }
            }).catch(e => {
            dispatch({
                type: 'setStatus',
                payload: {
                    value: 'Error',
                    message: e?.response?.data?.message || `There is a problem. Please try again later`
                }
            })
            reject()
        })
    });
}

export function fetchConfig(dispatch: React.Dispatch<Action>) {
    axios.get(`${process.env.REACT_APP_BASE_URL}/config`)
        .then((res) => {
            if (res.status === 200) {
                dispatch({
                    type: 'setConfig',
                    payload: {
                        walletAddress: res.data.data['creator'],
                        network: res.data.data['network'] === 'mainnet' ? 'mainnet-beta' : res.data.data['network']
                    }
                })
            }
        }).catch(e => {
        dispatch({
            type: 'setStatus',
            payload: {
                value: 'Error',
                message: e?.response?.data?.message || `There is a problem. Please try again later`
            }
        })
    });
}


export function validateAddress(dispatch: React.Dispatch<Action>, pubKey: PublicKey) {
    return new Promise<void>((resolve, reject) => {
        axios.get(`${process.env.REACT_APP_BASE_URL}/validate/${pubKey.toString()}`)
            .then((res) => {
                if (res.data.status === 200) {
                    dispatch({
                        type: 'setValid',
                        payload: {
                            isValid: res.data.status === 200,
                        }
                    })
                    resolve()
                } else {
                    dispatch({
                        type: 'setStatus',
                        payload: {
                            value: 'Error',
                            message: res.data?.message
                        }
                    })
                    reject()
                }
            }).catch(e => {
            dispatch({
                type: 'setStatus',
                payload: {
                    value: 'Error',
                    message: `There is a problem. Please try again later`
                }
            })
            reject()
        })
    })
}


export function getImage(dispatch: React.Dispatch<Action>, token: string, payer: string) {
    axios({
        method: 'post',
        url: `${process.env.REACT_APP_BASE_URL}/get-token-image/${token}`,
        data: {payer}
    }).then(res => {
        if (res.data.data.image_uri) {
            dispatch({
                type: 'setImage',
                payload: {
                    uri: res.data.data.image_uri,
                    explorer: res.data.data.explorer_uri
                }
            })
            dispatch({
                type: 'setStatus',
                payload: {
                    value: 'Successful',
                    message: res.data.data.message
                }
            })
        } else {
            dispatch({
                type: 'setStatus',
                payload: {
                    value: 'Error',
                    message: `There is a problem. Please check your wallet's Collection`
                }
            })
        }
    }).catch(e => {
        dispatch({
            type: 'setStatus',
            payload: {
                value: 'Error',
                message: e.response?.data?.message || `There is a problem. Please try again later`
            }
        })
    })
}
