import React, { memo } from "react";
import { useTransition, animated } from "react-spring";

import styles from "./../styles/Clouds.module.css";

import cloudpink1 from "./../assets/images/cloudpink1.png";
import cloudpink2 from "./../assets/images/cloudpink2.png";
import cloudpink3 from "./../assets/images/cloudpink3.png";

function Clouds() {
  const imgCloud1 = <img src={cloudpink1} alt="cloud" />;
  const cloudTransition1 = useTransition(imgCloud1, {
    from: { x: -150, opacity: 0, blur: 10 },
    enter: { x: 0, opacity: 1, blur: 0 },
    leave: { x: -150, opacity: 0, blur: 10 },
    config: {
      mass: 2.3,
      tension: 99,
    },
  });

  const imgCloud2 = <img src={cloudpink2} alt="cloud" />;
  const cloudTransition2 = useTransition(imgCloud2, {
    from: { x: 900, opacity: 0, blur: 10 },
    enter: { x: 600, opacity: 1, blur: 0 },
    leave: { x: 900, opacity: 0, blur: 10 },
    config: {
      mass: 2.3,
      tension: 99,
    },
  });

  const imgCloud3 = <img src={cloudpink3} alt="cloud" />;
  const cloudTransition3 = useTransition(imgCloud3, {
    from: { x: 800, opacity: 0, blur: 10 },
    enter: { x: 400, opacity: 1, blur: 0 },
    leave: { x: 800, opacity: 0, blur: 10 },
    config: {
      mass: 2.3,
      tension: 99,
    },
  });

  return (
    <React.Fragment>
      <div className={styles.cloudPink1}>
        {cloudTransition1(
          ({ blur, ..._styles }, content) =>
            content && (
              <animated.div
                style={{
                  ..._styles,
                  filter: blur.to((val) => `blur(${val}px)`),
                }}
              >
                {content}
              </animated.div>
            )
        )}
      </div>

      <div className={styles.cloudPink2}>
        {cloudTransition2(
          ({ blur, ..._styles }, content) =>
            content && (
              <animated.div
                style={{
                  ..._styles,
                  filter: blur.to((val) => `blur(${val}px)`),
                }}
              >
                {content}
              </animated.div>
            )
        )}
      </div>

      <div className={styles.cloudPink3}>
        {cloudTransition3(
          ({ blur, ..._styles }, content) =>
            content && (
              <animated.div
                style={{
                  ..._styles,
                  filter: blur.to((val) => `blur(${val}px)`),
                }}
              >
                {content}
              </animated.div>
            )
        )}
      </div>
    </React.Fragment>
  );
}

export default memo(Clouds);
